import { useAuth } from '@/hooks/useAuth';
import { getSliderSettings } from '@/utils';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import SkeletonLoader from '../loader/SkeltonLoader/SkeltonLoader';
import { categoryLoader } from '../loader/SkeltonLoader/loaderConfigs';
import Image from 'next/image';
import { getCdnUrl } from '@/lib/helpers/common';
export default function TopSlider() {
  const {
    home
  } = useSelector(store => store.common);
  const {
    getLocaleContent
  } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  let categoryData = home?.CATEGORY || [];
  useEffect(() => {
    if (categoryData) {
      setIsLoading(false);
    }
  }, [categoryData]);
  if (!categoryData?.length) {
    return null;
  }
  const responsiveSettings = [{
    breakpoint: 1366,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 3,
      dots: false,
      infinite: false
    }
  }, {
    breakpoint: 991,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 2
    }
  }, {
    breakpoint: 767,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1
    }
  }];
  const sliderSettings = getSliderSettings({
    data: categoryData || [],
    responsive: responsiveSettings
  });
  const items = categoryData?.map(category => <Link key={category.id} href={`/categories#${category.id}`}>
            <div className="items">
                <div className="item-bg">
                    <figure>
                        <Image src={getCdnUrl(category.categoryImage)} height={100} width={100} alt={getLocaleContent(category.name)} />
                    </figure>
                </div>
                <span>{getLocaleContent(category.name)}</span>
            </div>
        </Link>);
  if (isLoading) return <SkeletonLoader className="p3" layout={categoryLoader} />;
  return <Slider {...sliderSettings} data-sentry-element="Slider" data-sentry-component="TopSlider" data-sentry-source-file="topslider..jsx">{items}</Slider>;
}