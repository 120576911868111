const { gql } = require('@apollo/client');

export const SEARCH_PRODUCTS = gql`
    query searchProductQuery($keyword: String!, $companyId: Int!, $storeId: Int, $orderTypes: [String!]!) {
        searchProductQuery(keyword: $keyword, companyId: $companyId, storeId: $storeId, orderTypes: $orderTypes) {
            id
            name {
                en
                ar
            }
            description {
                en
                ar
            }
        }
    }
`;
