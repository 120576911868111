import { useTranslations } from 'next-intl';
import Slider from 'react-slick';
import { modalTypeKey, ORDER_TYPES } from '@/configs/constants';
import { useAuth } from '@/hooks/useAuth';
import useReduxUtils from '@/hooks/useReduxUtils';
import useUrlParams from '@/hooks/useUrlParams';
import { getCdnUrl } from '@/lib/helpers/common';
import { addLoader, removeLoader } from '@/redux/slices/common';
import { getNearByStores } from '@/service/home.service';
import { isFalsy } from '@/utils';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Location from '../icons/Location';
import { modalTypes } from '../ModalWrapper';
function Outlets() {
  const router = useRouter();
  const dispatch = useDispatch();
  const t = useTranslations('Home');
  const [outlets, setOutlets] = useState([]);
  const accountData = useSelector(store => store.account)?.account;
  const orderData = useSelector(store => store.order)?.order;
  const {
    getLocaleContent
  } = useAuth();
  const {
    addMultipleParams
  } = useUrlParams();
  const {
    getSelectedLocation
  } = useReduxUtils();
  useEffect(() => {
    if (!accountData?.deviceLocation?.data || !orderData?.address?.geoData?.coordinates) return;
    fetchOutlets();
  }, [accountData?.deviceLocation?.data, orderData?.address?.geoData?.coordinates]);
  const fetchOutlets = async () => {
    const selectedLocation = getSelectedLocation();
    if (!isFalsy(selectedLocation || {})) {
      try {
        dispatch(addLoader('getNearByStoresData'));
        const response = await getNearByStores({
          ...selectedLocation,
          type: [ORDER_TYPES.TAKEAWAY]
        });
        setOutlets(response?.data);
        return response;
      } catch (error) {
        console.error('error', error);
      } finally {
        dispatch(removeLoader('getNearByStoresData'));
      }
    }
  };
  if (!outlets.length) return;
  const settings = {
    dots: false,
    rtl: router.locale === 'ar',
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [{
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 2,
        infinite: outlets?.length > 3
      }
    }, {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: outlets?.length > 1
      }
    }]
  };
  const handleOrderNowClick = (coordinates, itemId) => {
    addMultipleParams({
      [modalTypeKey]: modalTypes.selectOrderType,
      redirect: 'categories',
      latlng: [...coordinates].reverse(),
      storeId: itemId
    });
  };
  const handleLocationClick = latlngArray => {
    // open google map in new tab
    window.open(`https://www.google.com/maps/search/?api=1&query=${latlngArray[0]},${latlngArray[1]}`, '_blank');
  };
  return <>
            <h4 className="">
                <small>{t('nearby')}</small> {t('outlets')}
            </h4>
            <p className="pb-50">{t('nearby_outlet_description')}</p>

            <Slider {...{
      ...settings,
      infinite: outlets?.length > 4,
      dots: false
    }} data-sentry-element="Slider" data-sentry-source-file="Outlets.jsx">
                {outlets?.map(item => <div className="outlets-items" key={item.id}>
                        <figure>
                            <Image src={getCdnUrl(item.photo)} alt="" width={100} height={100} layout="response" quality={75} />
                            <figcaption>
                                <h6>{getLocaleContent(item.name)}</h6>
                                <h5>{getLocaleContent(item.address?.city || {})}</h5>

                                <div className="btn-group">
                                    <button className="btn" onClick={() => handleOrderNowClick(item.location.coordinates, item.id)}>
                                        {t('order_now_button')}
                                    </button>
                                    <button className="btn-icon" onClick={() => handleLocationClick([...item.location.coordinates].reverse())}>
                                        <Location />
                                    </button>
                                </div>
                            </figcaption>
                        </figure>
                    </div>)}
            </Slider>
        </>;
}
export default Outlets;