import { useSelector } from 'react-redux';
const useReduxUtils = () => {
  const accountData = useSelector(store => store.account)?.account;
  const orderData = useSelector(store => store.order)?.order;
  const commonData = useSelector(store => store.common);
  const getSelectedLocation = () => {
    let location = {};
    if (accountData?.deviceLocation?.data) {
      location = accountData?.deviceLocation?.data;
    }
    if (orderData?.address?.geoData?.coordinates) {
      location = orderData?.address?.geoData?.coordinates;
    }
    return location;
  };
  const parsePhoneNumber = phoneNumber => {
    const parsedPhoneNumber = {};
    if (phoneNumber.includes(' ')) {
      parsedPhoneNumber.phoneNumber = phoneNumber.split(' ')[1] || '';
      parsedPhoneNumber.phoneCode = phoneNumber.split(' ')[0] || '';
      parsedPhoneNumber.countryCode = commonData.masterCountries.find(i => i.phoneCode === parsedPhoneNumber.phoneCode)?.isoCode || '';
    } else {
      parsedPhoneNumber.phoneNumber = phoneNumber || '';
    }
    return parsedPhoneNumber;
  };
  const getMasterCountry = ({
    countryCode,
    phoneCode
  }) => {
    if (phoneCode) {
      return commonData.masterCountries.find(i => i.phoneCode === phoneCode);
    } else {
      return commonData.masterCountries.find(i => i.isoCode === countryCode);
    }
  };
  const isLocationPermissionDenied = () => {
    return !orderData?.address?.geoData?.coordinates?.lat && !accountData?.deviceLocation?.isLocationAllowed;
  };
  return {
    getSelectedLocation,
    parsePhoneNumber,
    getMasterCountry,
    isLocationPermissionDenied
  };
};
export default useReduxUtils;