import Appstore from '@/components/icons/Appstore';
import Playstore from '@/components/icons/Playstore';
import Link from 'next/link';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { assetPath } from '@/lib/helpers/common';
const GetTheApp = () => {
  const t = useTranslations('Home');
  return <div className="storeSection" data-sentry-component="GetTheApp" data-sentry-source-file="getTheApp.jsx">
            <div className="container">
                <div className="storeWrapper">
                    <div className="textBlock">
                        <h3>
                            {t('get_the')}
                            <br />
                            {t('chicking_app')}
                        </h3>
                        <p>{t('app_description')}</p>
                        <div className="storeIcons">
                            <Link href="https://play.google.com/store/apps/details?id=com.chickinguae" data-sentry-element="Link" data-sentry-source-file="getTheApp.jsx">
                                <Playstore data-sentry-element="Playstore" data-sentry-source-file="getTheApp.jsx" />
                            </Link>
                            <Link href="https://apps.apple.com/us/app/id1451570624" data-sentry-element="Link" data-sentry-source-file="getTheApp.jsx">
                                <Appstore data-sentry-element="Appstore" data-sentry-source-file="getTheApp.jsx" />
                            </Link>
                        </div>
                    </div>
                    <div className="imageBlock">
                        <Image src={assetPath('images/MobileImage.png')} height={500} width={500} alt="" quality={75} layout="responsive" priority={true} data-sentry-element="Image" data-sentry-source-file="getTheApp.jsx" />
                    </div>
                </div>
            </div>
        </div>;
};
export default GetTheApp;